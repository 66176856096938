import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-257c30ad"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card border-0 d-flex flex-row align-items-center rounded-0 py-3" }
const _hoisted_2 = { class: "card-icon d-flex justify-content-center align-items-center" }
const _hoisted_3 = ["href"]
const _hoisted_4 = { class: "card-body p-0 d-flex flex-column px-2" }
const _hoisted_5 = {
  key: 0,
  class: "card-title fw-bold"
}
const _hoisted_6 = {
  key: 1,
  class: "card-title fw-bold text-break"
}
const _hoisted_7 = {
  key: 2,
  class: "text-color--medium-emphasis"
}
const _hoisted_8 = {
  key: 3,
  class: "text-color--medium-emphasis"
}
const _hoisted_9 = {
  key: 4,
  class: "text-color--medium-emphasis"
}
const _hoisted_10 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("a", {
        class: _normalizeClass(["card-link link-primary flycon flycon-end flycon-32", {
          'flycon-file-text': _ctx.item.type === 'application/pdf' && !_ctx.urlList,
          'flycon-photo': _ctx.item.type === 'image/pjpeg' && !_ctx.urlList,
          'flycon-domain': _ctx.item.type === 'url' || _ctx.urlList,
        }]),
        href: _ctx.item.url
      }, null, 10, _hoisted_3)
    ]),
    _createElementVNode("div", _hoisted_4, [
      (!_ctx.urlList)
        ? (_openBlock(), _createElementBlock("h4", _hoisted_5, _toDisplayString(_ctx.item.label), 1))
        : _createCommentVNode("", true),
      (_ctx.urlList)
        ? (_openBlock(), _createElementBlock("h4", _hoisted_6, _toDisplayString(_ctx.item.link), 1))
        : _createCommentVNode("", true),
      (_ctx.item.type === 'application/pdf' && !_ctx.urlList)
        ? (_openBlock(), _createElementBlock("span", _hoisted_7, "PDF Dokument"))
        : _createCommentVNode("", true),
      (_ctx.item.type === 'image/pjpeg' && !_ctx.urlList)
        ? (_openBlock(), _createElementBlock("span", _hoisted_8, "BILD"))
        : _createCommentVNode("", true),
      (_ctx.item.type === 'url' && !_ctx.urlList)
        ? (_openBlock(), _createElementBlock("span", _hoisted_9, "Webseite"))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("a", {
      class: "card-link link-dark stretched-link flycon flycon-end flycon-24 flycon-arrow-up-right",
      href: _ctx.item.link,
      target: "blank"
    }, null, 8, _hoisted_10)
  ]))
}