
import { defineComponent } from "@vue/runtime-core"

export default defineComponent({
  props: {
    item: {
      type: Object,
    },
    list: {
      type: Object,
    },
    index: {
      type: Number,
    },
    urlList: {
      type: Boolean,
      default: (): boolean => false,
    },
  },
})
