
import { defineComponent } from "@vue/runtime-core"
import { getPlaceholder, getFormatedResourceType } from "@/types"
import ResourceCard from "./ResourceCard.vue"

export default defineComponent({
  props: {
    list: {
      type: Object,
    },
    urlList: {
      type: Boolean,
      default: (): boolean => false,
    },
    wrapperClass: {
      type: String,
      default: (): string => "",
      require: false,
    },
  },
  components: { ResourceCard },
  setup() {
    return {
      getFormatedResourceType: getFormatedResourceType,
      getPlaceholder: getPlaceholder,
    }
  },
})
