
import { defineComponent } from "@vue/runtime-core"
import { computed } from "vue"
import Popper from "vue3-popper"
import momentImport from "moment"
import { DateFormatter, StringHelper } from "@/helpers"
export default defineComponent({
  props: {
    heading: {
      type: String,
      default: (): string => "",
    },
    content: {
      type: String,
      default: (): string => "",
    },
    spacing: {
      type: String,
      default: (): string => "pb-3",
    },
    tooltipWrapperClass: {
      type: String,
      required: false,
      default: (): string => "",
    },

    dateFrom: {
      type: String,
      default: (): string => "",
    },
    dateUntil: {
      type: String,
      default: (): string => "",
    },
    dateFromDescription: {
      type: String,
      default: (): string => "",
    },
    dateUntilDescription: {
      type: String,
      default: (): string => "",
    },
    dateInterval: {
      type: String,
      default: (): string => "",
    },
    label: {
      type: String,
      default: (): string => "",
    },
  },
  components: {
    Popper,
  },
  setup(props) {
    const moment = momentImport
    moment.locale("de")
    const f = "DD. MMMM"
    // console.log("props.content", props.content)
    return {
      moment: moment,
      f: f,
      dateFormatter: DateFormatter,
      formatedContent: computed(() => {
        if (
          props.label !== "" &&
          props.dateFrom == "" &&
          props.dateUntil == "" &&
          props.content === ""
        ) {
          return ""
        }
        return props.content === "" ? "-" : props.content
      }),
      stringHelper: StringHelper,
    }
  },
})
