
import { computed, defineComponent, onMounted, onUnmounted, ref } from "vue"
import { useStore } from "@/store"
import { MeasureActionTypes } from "@/store/modules/measure/action-types"
import TextSnippet from "@/components/TextSnippet.vue"
import ResourcesContainer from "@/components/resource/ResourcesContainer.vue"
import CardTextSnippet from "@/components/card/CardTextSnippet.vue"
import CardFloating from "@/components/card/CardFloating.vue"
import moment from "moment"
import Modal from "@/components/modal/Modal.vue"
import TestSystemContainer from "@/components/TestSystemContainer.vue"
import {
  ArrayFormatter,
  MeasurementFormatter,
  BooleanFormatter,
  DurationFormatter,
  CurrencyFormatter,
  DateFormatter,
  URLFormatter,
  StringHelper,
} from "@/helpers"
import { useRouter } from "vue-router"
import DateRangeDescriptionSnippet from "@/components/date-range/DateRangeDescriptionSnippet.vue"
import Popper from "vue3-popper"
import {
  getMeasureBonusItemOutput,
  getLivestockOutput,
  getPlaceholder,
} from "@/types"
import HtmlContentContainer from "@/components/HtmlContentContainer.vue"
import CardBorderedBody from "@/components/card/CardBorderedBody.vue"
import CardBordered from "@/components/card/CardBordered.vue"
// import DateRangeIntervalDescription from "@/components/DateRangeIntervalDescription.vue"

export default defineComponent({
  name: "MeasuresDetail",
  // eslint-disable-next-line vue/no-unused-components
  components: {
    TextSnippet,
    ResourcesContainer,
    CardTextSnippet,
    CardFloating,
    DateRangeDescriptionSnippet,
    Popper,
    CardBorderedBody,
    CardBordered,
    HtmlContentContainer,
    TestSystemContainer,
    Modal,
    // DateRangeIntervalDescription,
  },
  setup() {
    const selectedTab = ref(0)
    const tabShadow = ref(false)
    const header = ref(null)
    const tabBody = ref(null)
    const tabWrapper = ref(null)
    const store = useStore()
    const router = useRouter()

    const tabs = [
      "Allgemein",
      "Antragsstellung",
      // "Förderprogramm",
      "Flächenvorgaben",
      "Flächenanlage",
      "Verpflichtungen",
      "Dokumentationspflichten",
      "Sonstiges",
    ]
    // TODO - enable below line to change the path when assets images are loaded
    function bgImage(src: string) {
      // let img = "@/assets/" + src
      return {
        backgroundImage: `url("${src}")`,
      }
    }

    // To add scroll Event - Shadow on Tabs
    onMounted(() => {
      window.addEventListener("scroll", handleScroll)
    })
    onUnmounted(() => {
      window.removeEventListener("scroll", handleScroll)
    })
    const handleScroll = () => {
      tabShadow.value = window.scrollY >= 735
    }

    //Scroll to top
    function scrollToTop() {
      const headerElement: HTMLElement = header?.value || new HTMLElement()
      const tabBodyElement: HTMLElement = tabBody.value || new HTMLElement()
      const main: HTMLElement =
        document.getElementById("main-container") || new HTMLElement()
      const tabWrapperElement: HTMLElement =
        tabWrapper?.value || new HTMLElement()
      const paddingTop = Number(
        window
          .getComputedStyle(main, null)
          .getPropertyValue("padding-top")
          .slice(0, -2)
      )
      const paddingTabBar = Number(
        window
          .getComputedStyle(tabBodyElement, null)
          .getPropertyValue("padding-top")
          .slice(0, -2)
      )
      const containerHeight =
        paddingTop +
        headerElement.getBoundingClientRect().height -
        tabWrapperElement.getBoundingClientRect().height -
        paddingTabBar

      if (tabWrapperElement.getBoundingClientRect().top <= 80) {
        setTimeout(function () {
          window.scrollTo(0, containerHeight)
        }, 10)
      }
    }

    return {
      header,
      tabBody,
      tabWrapper,
      measure: computed(() => store.getters.getMeasureDetail),
      moment: computed(() => {
        const mom = moment
        mom.locale("de")
        return mom
      }),
      handleScroll,
      scrollToTop,
      tabShadow,
      tabs,
      selectedTab,
      bgImage,
      arrayFormatter: ArrayFormatter,
      areaUnitFormatter: MeasurementFormatter,
      booleanFormatter: BooleanFormatter,
      durationFormatter: DurationFormatter,
      currencyFormatter: CurrencyFormatter,
      dateFormatter: DateFormatter,
      stringHelper: StringHelper,
      getShortMesaureLabel: (label: string): string => {
        let max = 10
        if (label.length > max) {
          label = label.substr(0, max) + "…"
        }
        return label
      },
      navigateTo: (id: string, label: string): void => {
        store.dispatch(MeasureActionTypes.GET_MEASURE, id)
        router.replace({
          name: "MeasuresDetail",
          params: {
            id: id,
            label: URLFormatter.encodeStringForURL(label),
          },
        })
      },
      getMeasureBonusItemOutput: getMeasureBonusItemOutput,
      getLivestockOutput: getLivestockOutput,
      getPlaceholder: getPlaceholder,
    }
  },
})
