import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ResourceCard = _resolveComponent("ResourceCard")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.list.length == 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.getPlaceholder()), 1))
      : _createCommentVNode("", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (item, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: index,
        class: _normalizeClass([
      {
        'border-bottom': index !== _ctx.list.length - 1,
        'pb-0': _ctx.list.length === 1 || index === _ctx.list.length - 1,
      },
      _ctx.wrapperClass,
    ])
      }, [
        _createVNode(_component_ResourceCard, {
          item: item,
          list: _ctx.list,
          index: index,
          urlList: _ctx.urlList
        }, null, 8, ["item", "list", "index", "urlList"])
      ], 2))
    }), 128))
  ], 64))
}