import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.htmlContent)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(_ctx.wrapperClass)
      }, [
        _createElementVNode("p", {
          class: "mb-0",
          innerHTML: _ctx.htmlContent
        }, null, 8, _hoisted_1)
      ], 2))
    : _createCommentVNode("", true)
}