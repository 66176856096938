
import { defineComponent } from "@vue/runtime-core"
import { getDocutoolUrl } from "@/api/common"

export default defineComponent({
  props: {
    id: String,
  },
  setup(props) {
    return {
      getDocutoolPath: () => {
        return getDocutoolUrl() + "/module/measure/" + props.id
      },
    }
  },
})
