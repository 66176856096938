
import { computed } from "@vue/reactivity"
import { defineComponent } from "@vue/runtime-core"
import Popper from "vue3-popper"
import { StringHelper } from "@/helpers"
export default defineComponent({
  props: {
    hasClass: {
      type: String,
      default: (): string => "",
    },
    heading: {
      type: String,
      default: (): string => "",
    },
    content: {
      type: String,
      default: (): string => "",
    },
    tooltip: {
      type: String,
      default: (): string => "",
    },
    tooltipWrapperClass: {
      type: String,
      required: false,
      default: (): string => "",
    },
  },
  components: {
    Popper,
  },
  setup(props) {
    return {
      formatedContent: computed(() => {
        return props.content === "" ? "-" : props.content
      }),
      stringHelper: StringHelper,
    }
  },
})
